import React from "react";
import "./FeelingProud.css";
import Vector from "assests/images/vector.png"

function FeelingProud(props) {
  const theme = props.theme;
  return (
    <div>
      <img src={Vector} style={{height:'100%', width:'100%'}} />
    </div>
  )
}

export default FeelingProud;
