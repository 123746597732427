/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Dhaibert",
  logo_name: "DA",
  nickname: "Dhaib / Abiera",
  full_name: "Dhaibert Abiera",
  subTitle: "Senior Software Developer with a passion for creating intuitive, reliable, and innovative solutions.",
  resumeLink:
    "https://drive.google.com/file/d/1gap6G71u-mJfU_ohtj0KNPRJBhm6UxXX/view?usp=sharing",
  mail: "mailto:dhaibertabiera@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  linkedin: "https://www.linkedin.com/in/dhaibert-abiera-997298234/",
  gmail: "dhaibertabiera@gmail.com",
  facebook: "https://www.facebook.com/dhaibert.abiera"
};

const skills = {
  data: [
    {
      title: "Software Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developed RESTful APIs using Django Rest, analysed data structure and schemas. ",
        "⚡ Developed a web application using Django Framework/Bootstrap/Javascript/HTML.",
        "⚡ Developed web and mobile application using React JS/ React native CLI.",
        "⚡ Using useful libraries in react such as Redux for state management, React hook forms, and more.",
        "⚡ Created a stored procedures to encapsulate complex queries.",
        "⚡ Developed a mobile applications using react native expo.",
        "⚡ Collaborated with project managers to select ambitious, but realistic coding milestones on pre-release software project development.",
        "⚡ Revised, modularized and updated old code bases to modern development standards, reducing operating costs and improving functionality.",
        "⚡ Introduced agile methodologies and development best practices to division to enhance product development.",
        "⚡ Coordinated build integrations tasks with multiple teams"
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        }, 
        {
          skillName: "ReactJS and React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        }, 
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },  
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },  
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },  
        
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Shantilal Shah Engineering College",
      subtitle: "Bachelor in Information Technology",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I'm currently pursuing my bachelors in Information Technology.",
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ssgec.ac.in/",
    },
    {
      title: "Dr. S. & S. S. Ghandhy College Of Engineering & Technology",
      subtitle: "Diploma in Information Technology",
      logo_path: "ssgandhy.png",
      alt_name: "SSGC",
      duration: "2018 - 2021",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ssgc.cteguj.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "M0001: MongoDB Basics",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Hackathon",
      subtitle: "Hack The Mountains",
      logo_path: "hackathon1.svg",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "hackathon",
      color_code: "#E2405F",
    },
    {
      title: "A300: Atlas Security",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Cyber Security & Cyber Forensics",
      subtitle: "Workshop at IIT Bombay",
      logo_path: "iit.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "MLH Local Hack Day: Build",
      subtitle: "Major League Hacking",
      logo_path: "mlh-logo.svg",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "Google",
      color_code: "#fe0037",
    },
    {
      title: "Hack20",
      subtitle: "Flutter International Hackathon",
      logo_path: "flutter.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Flutter International Hackathon",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Freelance and Part-timer",
  description:
    "I've mostly done projects on my own and I am actively looking for company that gave me growth, more knowledge and experience and challenges.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Senior Software Developer",
          company: "Traxion Tech",
          company_url: "https://www.traxiontech.net/",
          logo_path: "traxion2.png",
          duration: "June 2019 - March 2024",
          location: "Pasay, Philippines",
          description: `As a lead developer on one of the company's core projects, I worked with project managers to set ambitious yet achievable coding goals for pre-release software development.
          `,
          color: "#0071C5",
        },
        {
          title: "Senior Software Developer (Freelancer)",
          company: "Maginnovate Inc.",
          company_url: "https://www.facebook.com/maginnovateinc/",
          logo_path: "maginno.png",
          duration: "May 2021 – March 2023",
          location: "Tagum city, Philippines",
          description: `Developed RESTful APIs using Django Rest, Object–relational mapping setup. Developed a web application using Django Framework/Bootstrap/Javascript/HTML.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Frontend Developer (Part-time job)",
          company: "DavEx",
          company_url: "https://www.facebook.com/davexapp/",
          logo_path: "davex.png",
          duration: "April 2020 – May 2021",
          location: "Davao city, Philippines",
          description: `Developed web and mobile application using React JS/ React native CLI. Implement and coordinate to the API/Backend developer for the better approaches. Using Redux for state management. 
          `, 
          color: "#ee3c26",
        },
      ],
    }, 
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have extensive experience utilizing the latest technology tools to create a range of projects. My expertise includes developing Django REST API backends, Python scripts, and React projects. Some of my notable projects include:",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "dhaib.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
  //   link: "https://twitter.com/Harikrushn9",
  //   avatar_image_path: "blogs_image.svg",
  // },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Bitss",
      url: "http://bitss.com/",
      description:
        "URL shortener but in a branding purposes. It can make your url product link or your URL makes more readable and much and can use on advertising purposes.",
      languages: [
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Stripe",
          iconifyClass: "logos-stripe",
        },
      ],
    },
    {
      id: "1",
      name: "MyPocketDoctor",
      url: "https://mypocket.doctor/",
      description:
        "Provides a remote telemedicine services by constantly driving down the cost of healthcare thru the application of advanced mobile phone and web-based telehealth solutions and EMR systems",
      languages: [
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Node js",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Paypal",
          iconifyClass: "logos-paypal",
        },
      ],
    },
    {
      id: "0",
      name: "Easethetics",
      url: "https://easethetics.ph/",
      description:
        "Specially built to manage any type of Aesthetic, Cosmetic, Dermatologic clinics and MedSpas just by using any type of device!",
      languages: [
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        }, 
      ],
    },
    {
      id: "13",
      name: "Coopnet",
      url: "https://coopnet.online/",
      description:
        "A Network Solution made specifically for financial entities needing to connect with each other.",
      languages: [
        // {
        //   name: "HTML5",
        //   iconifyClass: "vscode-icons:file-type-html",
        // },
        // {
        //   name: "CSS3",
        //   iconifyClass: "vscode-icons:file-type-css",
        // },
        // {
        //   name: "ReactJS",
        //   iconifyClass: "logos-react",
        // },
        
        // {
        //   name: "NodeJS",
        //   iconifyClass: "logos-nodejs",
        // },
      ],
    },
    {
      id: "1",
      name: "DavEx - Food/Errand Delivery",
      url: "https://play.google.com/store/apps/details?id=com.davex.cx.prod",
      description:
        "A food delivery application Android and IOS build.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        }, 
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Node js",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        }, 
      ],
    },
    {
      id: "3",
      name: "Mypocketdoctor Mobile",
      url: "https://play.google.com/store/apps/details?id=com.maginnovate.mypocketdoctor&hl=en",
      description:
      "Provides a remote telemedicine services by constantly driving down the cost of healthcare thru the application of advanced mobile phone and web-based telehealth solutions and EMR systems",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        }, 
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Node js",
          iconifyClass: "logos-nodejs",
        },
      ],
    },
    
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
